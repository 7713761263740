<template>
  <section class="section-two">
    <div class="container mt-100">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Client's Review</h4>
            <p class="text-muted para-desc mx-auto mb-0">
              <span class="text-primary font-weight-bold">AIG</span> Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Nam ea obcaecati enim aut, tempora asperiores consequatur, distinctio architecto id alias ad
              tenetur. Eius omnis doloremque ad dolor. Nisi, velit nobis.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-12 mt-4">
          <Testimonial :testimonialData="testimonialData" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Testimonial from "@/components/testimonial";
  export default {
    components: {
      Testimonial,
    },
    data() {
      return {
        testimonialData: [
          {
            id: 1,
            profile: "/images/client/01.jpg",
            message:
              "It seems that only fragments of the original text remain in the Lorem Ipsum texts used today.",
            name: "Thomas Israel",
            designation: "C.E.O",
          },
          {
            id: 2,
            profile: "/images/client/02.jpg",
            message:
              "One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others.",
            name: "Barbara McIntosh",
            designation: "M.D",
          },
          {
            id: 3,
            profile: "/images/client/03.jpg",
            message:
              "The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century.",
            name: "Carl Oliver",
            designation: "P.A",
          },
          {
            id: 4,
            profile: "/images/client/04.jpg",
            message:
              "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
            name: "Christa Smith",
            designation: "Manager",
          },
          {
            id: 5,
            profile: "/images/client/05.jpg",
            message:
              "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
            name: "Dean Tolle",
            designation: "Developer",
          },
          {
            id: 6,
            profile: "/images/client/05.jpg",
            message:
              "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
            name: "Jill Webb",
            designation: "Designer",
          },
        ],
      };
    },
  };
</script>